
// set localStorage data
function _setLocalStorage(localStorageID, data) {
    localStorage.setItem(localStorageID, JSON.stringify(data));
}

// get localStorage data
function _getLocalStorage(localStorageID) {
    const localStrData = localStorage.getItem(localStorageID);
    if (!localStrData) return null;
    return JSON.parse(localStrData);
}

// clear localStorage data
function _removeLocalStorage(localStorageID) {
    localStorage.removeItem(localStorageID);
}


// state
const state = {
};


const getters = {
};


const mutations = {

    setLocalStorage: (state, { itemId, value }) => {
        _setLocalStorage(itemId, value);
    },

    getLocalStorage: (state, itemId) => {
       return _getLocalStorage(itemId);
    },

    removeLocalStorage: (state, itemId) => {
        _removeLocalStorage(itemId);
    }

}


const actions = {

}


// Export
export default {
    state,
    getters,
    mutations,
    actions,
}