import axios from "axios";

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    projectsTests: null,
}
const getters = {
    projectsTests: state => state.projectsTests,
}

const mutations = {
    setProjectsTests: (state, data) => state.projectsTests = data,
}

const actions = {
    fetchProjectsTests({commit}, href){
        axios.get(`${href}`).then(res => {
            commit('setProjectsTests', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },


}

export default {
    state,
    getters,
    mutations,
    actions,
}