import axios from 'axios';

/* STATE * * * * * * * * * * * * * * * * * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    changeLog: null,
    changeLogScheme: null,
}

/* GETTERS * * * * * * * * * * * * * * * * * * */
const getters = {
    changeLog: state => state.changeLog,
    changeLogScheme: state => state.changeLogScheme,
}

/* MUTATIONS * * * * * * * * * * * * * * * * * * */
const mutations = {
    setChangeLog: (state, data) =>   state.changeLog = data,
    setChangeLogScheme: (state, data) =>   state.changeLogScheme = data,
    setNewChangeLog: (state, data) =>   {
        const newArray = state.changeLog
        newArray.unshift(data)
        state.changeLog = []
        state.changeLog = newArray
    },
    setUpdatedChangeLog: (state, data) =>   {
        let newArray = state.changeLog
        let versionObjectIndex = newArray.findIndex((version => version.id ===  data.id));
        newArray[versionObjectIndex] = data
        state.changeLog = null
        state.changeLog = [...newArray]
    },
    setDeletedChangeLog: (state, data) =>   {
        let newArray = state.changeLog.filter(version => version.id !== data.id)
        state.changeLog = null
        state.changeLog = newArray
    },
}



/* ACTIONS * * * * * * * * * * * * * * * * * * */
const actions = {
    fetchChangeLog({commit}) {
        axios.get('/change_logs.json').then(res => {
            commit('setChangeLog', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewChangeLogScheme({commit}) {
        axios.get('/change_logs/new.json').then(res => {
            commit('setChangeLogScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async addNewChangeLogVersion({commit}, newVersion) {

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                change_log: newVersion,
            };

            const res = await axios.post(`/change_logs.json`, params , {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'created', text: 'a new version'});
            commit('setNewChangeLog', res.data);

            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    fetchEditChangeLogScheme({commit}, id) {
        axios.get(`/change_logs/${id}/edit.json`).then(res => {
            commit('setChangeLogScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateChangeLogVersion({commit}, {id, updatedVersion}) {

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                change_log: updatedVersion,
            };

            const res = await axios.put(`/change_logs/${id}.json`, params , {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'version'});
            commit('setUpdatedChangeLog', res.data);

            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

    async deleteChangeLogVersion({commit}, id){
        try {

            const res = await  axios.delete(`/change_logs/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'version'});
            commit('setDeletedChangeLog', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 8000});
            return Promise.reject(error);
        }
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
}