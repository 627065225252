import axios from 'axios';

const state = {
    newProjects: null,
    latestPosts: null,
    lastActivities:null,
    calendar:null,
    upcomingKeyDates:null,
}

const getters = {
    newProjects: state => state.newProjects,
    latestPosts: state => state.latestPosts,
    lastActivities: state => state.lastActivities,
    calendar: state => state.calendar,
    upcomingKeyDates: state => state.upcomingKeyDates,

}

const mutations ={
    setCards: (state, data) => {
        state.newProjects = data.new_projects;
        state.latestPosts = data.latest_posts;
        state.lastActivities = data.last_activities;
        state.calendar = data.calendar
        state.upcomingKeyDates = data.upcoming_key_dates
    },
}

const actions = {
    fetchDashboardCards({commit}) {
        axios.get(`/dashboard/data.json`).then(res => {
            commit('setCards', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

}

export default {
    state,
    getters,
    mutations,
    actions,
}
