import axios from "axios";

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    eventsList: null,
    clickedEvent: null,
    newEventScheme: null,
    editEventScheme: null,
    eventResponse: null,
}
const getters = {
    eventsList: state => state.eventsList,
    clickedEvent: state => state.clickedEvent,
    newEventScheme: state => state.newEventScheme,
    editEventScheme: state => state.editEventScheme,
    eventResponse: state => state.eventResponse,
}

const mutations = {
    setEventsList: (state, data) => state.eventsList = data,
    setClickedEvent: (state, data) => state.clickedEvent = data,
//Calendar Event
    setNewEventScheme: (state, data) => state.newEventScheme = data,
    setNewEvent: (state, data) => {
        let newObject = state.eventsList
        newObject.events.push(data)
        state.eventsList = null
        state.eventsList = newObject
    },
    setEditEventScheme: (state, data ) => state.editEventScheme = data,
    setUpdatedEvent: (state, data) => {
        let newObject = state.eventsList
        let eventObjectIndex = newObject.events.findIndex(( event => event.id ===  data.id));
        newObject.events[eventObjectIndex] = data
        state.eventsList = null
        state.eventsList = newObject
    },
    setDeletedEvent: (state, data) => {
        let newObject = state.eventsList
        newObject.events = newObject.events.filter(event => event.id !== data.id)
        state.eventsList = null
        state.eventsList = newObject
    },
    setEventResponse: (state, data) => state.eventResponse = data,
}

const actions = {
    fetchEventsList({commit}){
        axios.get(`/events.json`).then(res => {
            commit('setEventsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

// Clicked Event
    fetchClickedEvent({commit}, id){
        axios.get(`/events/${id}.json`).then(res => {
            commit('setClickedEvent', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

// Clicked Batch
    fetchClickedBatch({commit}, id){
        axios.get(`/batch_fields/${id}.json`).then(res => {
            commit('setClickedEvent', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Calender Event

    fetchNewEventScheme({commit}, id){
        axios.get(`/events/new.json`).then(res => {
            commit('setNewEventScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    addNewEvent({commit}, newEvent) {
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            event: newEvent,
        };

        axios.post(`/events.json`, params , {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'created', text: 'a new event'});
                commit('setNewEvent', res.data);
                commit('setEventResponse', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });
    },

    fetchEditEventScheme({commit}, id){
        axios.get(`/events/${id}/edit.json	`).then(res => {
            commit('setEditEventScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    updateEvent({commit}, {id, updatedEvent}){
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            event: updatedEvent,
            id: id,
        };
        axios.put(`/events/${id}.json`, params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'event'});
                commit('setUpdatedEvent', res.data);
                commit('setEventResponse', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });
    },

    deleteEvent({commit}, id){
        axios.delete(`/events/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'deleted', text: 'event'});
                commit('setDeletedEvent', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });
    },

    resetEventResponse({commit}){
        commit('setEventResponse', null);
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}