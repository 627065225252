<template>
<!--   Login Card   -->
            <v-card class="mx-lg-0 mx-md-0  mx-sm-auto d-flex flex-column justify-center align-center"
                         min-width="475"
                         max-width="500"
                         min-height="650"
                         color="white">
                   <v-container fluid >

                     <!--  PL Logo  -->
                     <v-row justify="space-between">
                       <v-col  cols="12" class="d-flex align-center justify-center">
                         <div  class="pl-logo-block plFull" data-cy="projectlink-logo"></div>
                       </v-col>

                       <v-col cols="12">
                         <!--   Error Message  -->
                         <v-row>
                           <v-col cols="12">
                             <v-alert v-if="failMessage" density="compact" variant="outlined" type="error" class="text-small mx-2" >{{failMessage}}</v-alert>
                           </v-col>
                         </v-row>


                         <!--   Login Form  -->
                         <v-row>
                           <v-col cols="12"  class="d-flex align-center justify-center" >
                             <v-card-text style="max-width: 400px;">
                               <h1 class="mb-6 text-gunmetal text-left">Login</h1>
                               <v-form action="/login" method="post" accept-charset="UTF-8" ref="form" data-cy="login-form">
                                 <input type="hidden" name="authenticity_token" id="authenticity_token" v-bind:value="csrfToken" />

                                 <v-label for="authentication_name"><span class="text-caption">Username</span></v-label>
                                 <v-text-field class="input-group--focused mt-1 pt-0"
                                               variant="outlined"
                                               density="compact"
                                               rounded="xl"
                                               prepend-inner-icon="mdi-account"
                                               type="text"
                                               name="authentication[name]"
                                               id="authentication_name"
                                               autocomplete="off"
                                               ref="name"
                                               :rules="nameRules"
                                               v-model="username"
                                               :error="invalidUserName"
                                               @keypress="onKeyPress"
                                               data-cy="username-input"
                                 />
                                 <v-label for="authentication_password"><span class="text-caption">Password</span></v-label>
                                 <v-text-field class="mt-1 pt-0"
                                               variant="outlined"
                                               density="compact"
                                               rounded="xl"
                                               @click:append-inner="showPassword = !showPassword"
                                               :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                               :type="showPassword ? 'text' : 'password'"
                                               prepend-inner-icon="mdi-lock"
                                               name="authentication[password]"
                                               id="authentication_password"
                                               autocomplete="off"
                                               ref="password"
                                               :error="invalidPassword"
                                               :rules="passwordRules"
                                               @keypress="onKeyPress"
                                               data-cy="password-input"
                                 />
                                 <v-btn v-if="!submitted"
                                        value="login" color="primary" variant="flat" rounded="0" block
                                        data-cy="login-btn"
                                        :loading="submitted"
                                        @click="onSubmit">Sign in</v-btn>
                                 <v-btn v-if="submitted"
                                        color="primary"
                                        variant="flat" rounded="0" block loading />
                               </v-form>
                             </v-card-text>
                           </v-col>
                         </v-row>
                       </v-col>

                       <v-col cols="12">
                         <!--   Card Footer Information  -->
                         <v-row>
                           <v-col cols="12" class="text-caption text-center text-grey">&copy; {{date}} International Consumer Research & Testing Ltd.</v-col>
                         </v-row>
                       </v-col>
                   </v-row>
                 </v-container>
            </v-card>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex';

export default {
    name: 'login-form',
    props: {
        token: {
          type: String,
        },
        notice: {
          type: String,
        },
        title: {
          type: String,
        },
        remote: {
          type: Boolean,
          required: true,
        }
    },

    data: function() {
        return {
            date: new Date().getFullYear(),
            username: null,
            showPassword: false,
            invalidUserName: false,
            invalidPassword: false,
            submitted: false,
            failMessage: null,
            csrfToken: null,
            attempts: 0,
            nameRules: [ v => !!v || 'Name is required', ],
            passwordRules: [ v => !!v || 'Password is required', ],
        }
    },

    computed: mapGetters(['user', 'images']),

    mounted() {
      if(!this.remote) {
        this.csrfToken = document.getElementById('app').dataset.csrf;
        this.failMessage = document.getElementById('app').dataset.notice;
        return;
      }
      this.username = this.user.name;
      this.$refs.password.focus();
      this.csrfToken = this.token;
      this.failMessage = this.notice;
    },

    watch: {
      attempts(count) {
        if(count > 1) window.location = window.location.href;
      }
    },

    methods: {
        ...mapMutations(['setError']),

      async validateForm() {
        const { valid } = await this.$refs.form.validate()
        if (valid) return true
        return false
      },

        async onSubmit() {
            if(await this.validateForm()){
              this.submitted = true;

              const {authentication_name, authentication_password} = this.$refs.form.getElementsByTagName('input');
              const valid = !!authentication_name.value && !!authentication_password.value;

              if(!valid) {
                this.invalidUserName = true;
                this.invalidPassword = true;
                this.failMessage = "Please enter valid username and password";
                this.submitted = false
                return false;
              }

              if(this.remote) return this.submitForm();
              this.$refs.form.submit();
            } else {

            }

        },

        onKeyPress(e) {
            if(e.keyCode === 13) this.onSubmit();
        },

        async submitForm() {
            try {
                //fetch csrf token
                const res = await this.axios.get('/login');
                const parser = new DOMParser();
                const doc = parser.parseFromString(res.data, 'text/html');
                this.csrfToken = doc.querySelector("meta[name='csrf-token']").getAttribute('content');

                const {authenticity_token, authentication_name, authentication_password} = this.$refs.form.getElementsByTagName('input');
                const form = `authenticity_token=${encodeURIComponent(this.csrfToken)}&authentication[name]=${encodeURIComponent(authentication_name.value)}&authentication[password]=${encodeURIComponent(authentication_password.value)}`;

              const vm = this;


                setTimeout(async () => {

                    const res = await vm.axios({
                      url: '/login.json',
                      method: 'POST',
                      data: form,
                      headers: {
                          "Content-Type": "application/x-www-form-urlencoded",
                      },
                      withCredentials: false,
                      maxRedirects: 0
                    });

                    vm.submitted = false;

                    if (!res.status) {
                        vm.invalidPassword = true;
                        vm.failMessage = 'Incorrect password - please try again';
                        vm.attempts += 1;
                        return;
                    }

                    await this.axios.get('/token');
                    window.location = window.location.href + '?' + Date.now()

                    vm.invalidPassword = false;
                    vm.failMessage = null;
                    vm.attempts = 0;
                    vm.$emit('closeLoginDialog');

                }, 0);
            } catch(error) {
                this.failMessage = error.notice || error;
            }
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
