
const state = {
    alertResponse: null,
}

const getters = {
    alertResponse: state => state.alertResponse,
}

const mutations = {
    setAlertResponse: (state, data) => {
        if(data !== null) {
            if(data.type === 'successful') {
                state.alertResponse = {
                    message: `<p class="mb-0">Successfully <strong class="text-uppercase text-body-1">${data.action}</strong> ${data.text}</p>`,
                    icon: 'mdi-cloud-check-variant',
                    options: { color: 'primary' },
                    status: true,
                    pageType: data.pageType,
                    data: data.data,
                    timeout: data.timeout,
                }
            } else if (data.type === 'unsuccessful'){
                state.alertResponse = {
                    message: '<p class="mb-0">Ooops, something went <strong class="text-uppercase text-body-1"> wrong </strong>, please try again!</p>',
                    icon: 'mdi-alert-circle',
                    options: { color: 'red' },
                    status: false,
                    timeout: data.timeout,
                }
            } else if (data.type === 'error') {
                state.alertResponse = {
                    message: `<p class="font-weight-black mb-0">${data.text}</p>`,
                    icon: 'mdi-alert-circle',
                    options: { color: 'red' },
                    status: true,
                    timeout: data.timeout,
                }
            }
        }
    }
}

const actions = {
    setAlertResponse({commit}){
        commit('setAlertResponse', null);
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}