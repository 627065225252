import axios from 'axios';

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    report: null,
    customisedReportScheme: null,
    downloadUrl: null,
}
const getters = {
    report: state => state.report,
    customisedReportScheme: state => state.customisedReportScheme,
    downloadUrl: state => state.downloadUrl
}

const mutations = {
    setReport: (state, data) => state.report = data,
    setCustomisedReportScheme: (state, data) => state.customisedReportScheme = data,
    setDownloadUrl: (state, data) => state.downloadUrl = data,

}

const actions = {
    async fetchReadyReports({commit}, url) {
        try {

            const res = await axios.get(`/${url}.json`);

            if(res.data.error) throw res.data.message
            commit('setReport', res.data);
            commit('setDownloadUrl', `/${url}.xlsx`);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error});
            return Promise.reject(error);
        }

    /*    try {
            const res = await axios.get(`/${url}.json`);

            if(res.status === 201 || res.status === 200){
                commit('setReport', res.data);
                commit('setDownloadUrl', `/${url}.xlsx`);

                return res.data
            }

            commit('setAlertResponse', { type: 'unsuccessful'});

        } catch(error) {
            commit('setError', error.toString());
        }*/
    },

    fetchCustomisedReportScheme({commit}, url){
        axios.get(`/${url}`).then(res => {
            commit('setCustomisedReportScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async customiseReport({commit}, customisedReport) {
        try {
            const encoded_params = btoa(JSON.stringify(customisedReport))

            const res = await axios.get(`/reports/${encoded_params}.json`);

            if(res.status === 201 || res.status === 200){
                if(res.data.error) console.error("RESPONSE:", res.data.message)
                commit('setReport', res.data);
                commit('setDownloadUrl', `/reports/${encoded_params}.xlsx`);

                return res.data
            }

            commit('setAlertResponse', { type: 'unsuccessful'});

        } catch(error) {
            commit('setError', error.toString());
        }
    }

}
export default {
    state,
    getters,
    mutations,
    actions,
}
