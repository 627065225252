import axios from 'axios';
import News from '../groups/groups';


/* * * STATE * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    //Folder
    newFolderScheme: null,
    editFolderScheme: null,
    //Folder -> Rights
        //Users
    userRightsList: null,
    userRightsScheme: null,

        //Groups
    groupRightsList: null,
    groupsRightsScheme: null,

    //Document
    documentAbout: null,
    //Uploads
    singleUploadDocScheme: null,
    //Doc -> Tags
    documentTags: null,
    documentTagsScheme: null,
    //Doc -> Comment
    documentComments: null,
    editCommentScheme: null,
    newCommentScheme: null,
    //Doc -> Share
    documentExternalShares: null,
    documentExternalSharesScheme: null,
    //Doc -> Versions
    newVersionScheme: null,
    documentVersions: null,
    editVersionScheme: null,
    //Doc -> History
    documentHistory: null,
    //Article Documents
    newArticleDocumentsScheme: null,
    //Lab Documents
    newLabDocumentsScheme: null,
    //Topic Documents
    newTopicDocumentsScheme: null,
    //Documents Scheme
    newDocumentScheme: null,
    //documentsList (Article, Topic, Lab)
    documentsList: null,
    isDownloading: null,
}

/* * * GETTERS * * */
const getters = {
    //Folder
    newFolderScheme: state => state.newFolderScheme,
    editFolderScheme: state => state.editFolderScheme,
    //Folder -> Rights
        //User
    userRightsList: state => state.userRightsList,
    userRightsScheme: state => state.userRightsScheme,
        //Group
    groupRightsList: state => state.groupRightsList,
    groupsRightsScheme: state => state.groupsRightsScheme,
    //Document
    documentAbout: state => state.documentAbout,
    //Uploads
    singleUploadDocScheme: state => state.singleUploadDocScheme,
    //Doc -> Tags
    documentTags: state => state.documentTags,
    documentTagsScheme: state => state.documentTagsScheme,
    //Doc -> Comment
    documentComments: state => state.documentComments,
    editCommentScheme: state => state.editCommentScheme,
    newCommentScheme: state => state.newCommentScheme,
    //Doc -> Share
    documentExternalShares: state => state.documentExternalShares,
    documentExternalSharesScheme: state => state.documentExternalSharesScheme,
    //Doc -> Versions
    newVersionScheme: state => state.newVersionScheme,
    documentVersions: state => state.documentVersions,
    editVersionScheme: state => state.editVersionScheme,
    //Doc -> History
    documentHistory: state => state.documentHistory,
    //ArticleDocuments
    newArticleDocumentsScheme: state => state.newArticleDocumentsScheme,
    //Lab Documents
    newLabDocumentsScheme: state => state.newLabDocumentsScheme,
    //Topic Documents
    newTopicDocumentsScheme: state => state.newTopicDocumentsScheme,
    //Document Scheme
    newDocumentScheme: state => state.newDocumentScheme,
    //documentsList(Article, Lab, Topics)
    documentsList: state => state.documentsList,
    //downloading
    isDownloading: state => state.isDownloading,
}

/* * * MUTATIONS * * */
const mutations = {
    //Folder
    setNewFolderScheme: (state, data) => state.newFolderScheme = data,
    setNewFolder: (state, data) => {
        let newArray = state.documentsList
        newArray.push(data)
        state.documentsList = null
        state.documentsList = newArray
    },
    setEditFolderScheme: (state, data) => state.editFolderScheme = data,
    setUpdatedFolder: (state, data) => {
        let newArray = state.documentsList
        let newArrayFolderIndex = newArray.findIndex((folder => folder.id == data.id));
        newArray[newArrayFolderIndex].name = data.name
        newArray[newArrayFolderIndex].description = data.description
        newArray[newArrayFolderIndex].share = data.share
        state.documentsList = null
        state.documentsList = newArray
    },

    setDeletedFolder: (state, data) => {
        let newArray = state.documentsList.filter(folder => folder.id !== data.id)
        state.documentsList = null
        state.documentsList = newArray
    },
    //Folder -> Rights

    //User Rights
    setUserRightsList: (state, data) => state.userRightsList = data,

    setUserRightsScheme: (state, data) => state.userRightsScheme = data,

    setUpdatedUserRights(state, data){
        let userRightsListCopy = state.userRightsList
        let userRightsListIndex = userRightsListCopy.findIndex((folder => folder.id == data.id));
        userRightsListCopy[userRightsListIndex] = data
        state.userRightsList = null
        state.userRightsList = userRightsListCopy
    },

    //Group Rights
    setGroupRightsList: (state, data) => state.groupRightsList = data,

    setGroupsRightsScheme: (state, data) => state.groupsRightsScheme = data,

    setEditGroupsRights: (state, data) => state.editGroupsRights = data,

    setNewGroupRights(state, data){
        let groupRightsListCopy = state.groupRightsList
        groupRightsListCopy.push(data)
        state.groupRightsList = null
        state.groupRightsList = groupRightsListCopy
    },

    setUpdatedGroupRights(state, data){
        let groupRightsListCopy = state.groupRightsList
        let groupRightsListIndex = groupRightsListCopy.findIndex((folder => folder.id == data.id));
        groupRightsListCopy[groupRightsListIndex] = data
        state.groupRightsList = null
        state.groupRightsList = groupRightsListCopy
    },

    setDeletedGroupRights(state, data){
        let groupRightsListCopy = state.groupRightsList.filter(groupRight => groupRight.id !== data.id)
        state.groupRightsList = null
        state.groupRightsList = groupRightsListCopy
    },

    //Document
    setDeletedDocument: (state, data) => {
        if(!state.documentsList) return
            let newArray = state.documentsList
            let newArrayFolderIndex = newArray.findIndex((folder => folder.id == data.doc_folder_id));
            const newFolderChildren = newArray[newArrayFolderIndex].children.filter(doc => doc.id !== data.id)
            newArray[newArrayFolderIndex].children = newFolderChildren
            state.documentsList = null
            state.documentsList = newArray
    },
    setDocumentAbout: (state, data) => state.documentAbout = data,
    //Doc - > Uploads
    setUploadedDocuments: (state, data) => {
        if(!state.documentsList) return

        let newArray = state.documentsList
        let newArrayFolderIndex = newArray.findIndex((folder => folder.id === data.doc_folder_id));
        const document = {
            ...data,
            updatedby: data.user.name,
            version_no: 1,
        }
        newArray[newArrayFolderIndex].children.unshift(document)
        state.documentsList = null
        state.documentsList = newArray

    },
    setSingleUploadDocScheme: (state, data) => state.singleUploadDocScheme = data,
    //Doc -> Tags
    setDocumentTags: (state, data) => state.documentTags = data,
    setDocumentTagsScheme: (state, data) => state.documentTagsScheme = data,
    setNewDocumentTags:  (state, data) => {
        let newArray = state.documentTags
        newArray.push(...data)
        state.documentTags = null
        state.documentTags = newArray
    },
    setDeletedDocumentTag: (state, data) => {
        let newArray = state.documentTags
        state.documentTags = newArray.filter(doc => doc.id !== data.id)
    },
    //Doc -> Comment
    setDocumentComments: (state, data) => state.documentComments = data.reverse(),
    setNewCommentScheme: (state, data) => state.newCommentScheme = data,
    setNewComment:(state, data) => {
        let newArray = state.documentComments
        newArray.unshift(data)
        state.documentComments = newArray
    },
    setEditCommentScheme: (state, data) => state.editCommentScheme = data,
    setUpdatedDocumentComment: (state, data) => {
        const newArray = state.documentComments
        let commentObjectIndex = newArray.findIndex((comment => comment.id ==  data.id));
        newArray[commentObjectIndex].comment = data.comment
        state.documentComments = null
        state.documentComments = [...newArray]
    },
    //Doc -> Share
    setDocumentExternalShares: (state, data) => state.documentExternalShares = data,
    setDocumentExternalSharesScheme: (state, data) => state.documentExternalSharesScheme = data,
    setNewDocumentExternalShare: (state, data) => {
        let newArray = state.documentExternalShares
        newArray.unshift(data)
        state.documentExternalShares = null
        state.documentExternalShares = newArray
    },
    setDeletedDocumentExternalShare: (state, data) => {
        let newArray = state.documentExternalShares.filter(record => record.id !== data.id)
        state.documentExternalShares = null
        state.documentExternalShares = newArray
    },
    //Multiple Selection
    setMultipleDelete: (state, data) => {
        let newArray = state.documentsList
        let folderIndex = newArray.findIndex(folder => folder.id === data.doc_folder_id)
        data.documents.forEach((id, index) => {
            let docIndex = newArray[folderIndex].children.indexOf(data.documents[index]);
            if (index > -1) {
                newArray[folderIndex].children.splice(docIndex, 1);
            }
        })
        state.documentsList = null
        state.documentsList = newArray
    },
    setMultipleArchive: (state, data) => {
        let newArray = state.documentsList
        let folderIndex = newArray.findIndex(folder => folder.id === data.doc_folder_id)
        data.documents.forEach((id, index) => {
            let docIndex = newArray[folderIndex].children.findIndex(doc => doc.id === data.documents[index]);
            newArray[folderIndex].children[docIndex].archive = true
        })
        state.documentsList = null
        state.documentsList = newArray
    },
    setDocFolderTags: (state, data) => {
        let newArray = state.documentsList
        let folderIndex = newArray.findIndex(folder => folder.id === data.doc_folder_id)
        newArray[folderIndex].tags = data.tags
        state.documentsList = null
        state.documentsList = newArray

    },
    //Doc -> Lock
    setLockedDocument: (state, data) => {
           if(state.documentAbout){
               const newDoc = state.documentAbout
               newDoc['lock_user_id'] = data['lock_user_id']
               state.documentAbout = null
               state.documentAbout = newDoc
           }

           if(!state.documentsList) return

           let newArray = state.documentsList
           let newArrayFolderIndex = newArray.findIndex((folder => folder.id === data.doc_folder_id));
           let newArrayDocIndex = newArray[newArrayFolderIndex].children.findIndex((doc => doc.id === data.id));
           newArray[newArrayFolderIndex].children[newArrayDocIndex].lock_user_id = data.lock_user_id
           state.documentsList = null
           state.documentsList = newArray

        },
    setDeleteComment:(state, data) => {
        let newArray = state.documentComments.filter(comment => comment.id !== data.id)
        state.documentComments = newArray
    },
    //Doc -> Versions
    setNewVersionScheme: (state, data) => state.newVersionScheme = data,
    setNewVersions: (state, data) => {
        let documentVersionsCopy = state.documentVersions
        documentVersionsCopy.unshift(data)
        state.documentVersions = null
        state.documentVersions = documentVersionsCopy

        if(state.documentsList) {
            let documentsCopy = state.documentsList
            let folderIndex = documentsCopy.findIndex((folder => folder.id === data.document.doc_folder_id));
            let docIndex = documentsCopy[folderIndex].children.findIndex((doc => doc.id === data.document.id));
            documentsCopy[folderIndex].children[docIndex].lock_user_id = 0
            state.documentsList = null
            state.documentsList = documentsCopy
        }

        if(!state.documentAbout) return
        let documentAboutCopy = state.documentAbout
        documentAboutCopy.lock_user_id = 0
        state.documentAbout = null
        state.documentAbout = documentAboutCopy

    },
    setDocumentVersions: (state, data) => state.documentVersions= data,
    setEditVersionScheme: (state, data) => state.editVersionScheme = data,
    setUpdatedVersion(state, data){
        const documentVersionsCopy = state.documentVersions
        let commentIndex = documentVersionsCopy.findIndex((comment => comment.id ==  data.id));
        documentVersionsCopy[commentIndex].comments = data.comments
        state.documentVersions = null
        state.documentVersions = documentVersionsCopy
    },

    setDeletedVersion(state, data){
        let documentVersionsCopy = state.documentVersions.filter(docVersion => docVersion.id !== data.deleted_version.id)
        state.documentVersions = null
        state.documentVersions = documentVersionsCopy
    },

    //Doc -> History
    setDocumentHistory: (state, data) => state.documentHistory = data,

    setArchiveDocument: (state, data) => {
        if(state.documentsList){
            let newArray = state.documentsList
            let newArrayFolderIndex = newArray.findIndex((folder => folder.id == data.doc_folder_id));
            const newDocumentIndex = newArray[newArrayFolderIndex].children.findIndex(doc => doc.id === data.id)
            newArray[newArrayFolderIndex].children[newDocumentIndex].archive = data.archive
            state.documentsList = null
            state.documentsList = newArray
        }

        if(!state.documentAbout) return

        let documentAboutCopy = state.documentAbout
        documentAboutCopy.archive = data.archive
        state.documentAbout = null
        state.documentAbout = documentAboutCopy

    },

    //ArticleDocuments
    setNewArticleDocumentsScheme: (state, data) => state.newArticleDocumentsScheme = data,

    //Lab Documents
    setNewLabDocumentsScheme: (state, data ) => state.newLabDocumentsScheme = data,

    //Topic Documents
    setNewTopicDocumentsScheme: (state, data ) => state.newTopicDocumentsScheme = data,

    //Documents Scheme
    setNewDocumentScheme: (state, data ) => state.newDocumentScheme = data,

    //All Docs (Article, Lab, Topic)
    setDocumentsList:  (state, data ) => state.documentsList = data,

    //Downloading
    setIsDownloading: (state, data ) => state.isDownloading = data,
}

/* * * ACTIONS * * */
const actions = {

/* * * * - - -  FOLDER - - -  * * * */

//New Folder
    fetchNewFolderScheme({commit}, id){
        axios.get(`/projects/${id}/doc_folders/new.json`).then(res => {
            commit('setNewFolderScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createFolder({commit}, {newFolder, id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_folder: newFolder,
            };

            const res = await axios.post(`/projects/${id}/doc_folders.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) return commit('setAlertResponse', {type: 'error', text: res.data.message});
            commit('setAlertResponse', {type: 'successful', action: "created", text: 'a new document folder'});
            commit('setNewFolder', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

    },

//Edit Folder
    fetchEditFolderScheme({commit}, id){
        axios.get(`/doc_folders/${id}/edit.json`).then(res => {
            commit('setEditFolderScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateFolder({commit}, {updatedFolder, id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_folder: updatedFolder,
                id: id,
            };

            const res = await axios.put(`/doc_folders/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: "updated", text: 'folder'});
            commit('setUpdatedFolder', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

//Delete Folder
    deleteFolder({commit}, id) {
        axios.delete(`/doc_folders/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', { type: 'successful', action: "deleted", text: 'folder'});
                commit('setDeletedFolder', res.data);
            } else {
                commit('setAlertResponse', { type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });
    },

 /* * * - - - Folder Rights - - -  * * * */

//USERS Rights
    fetchFolderUserRights({commit}, id){
        axios.get(`/doc_folders/${id}/doc_folder_users.json`).then(res => {
            commit('setUserRightsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchEditUserRights({commit}, id){
        axios.get(`/doc_folder_users/${id}/edit.json`).then(res => {
            commit('setUserRightsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })

    },

    async updateUserRights({commit}, {updatedUsers, id}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_folder_user: updatedUsers,
            };

            const res = await axios.put(`/doc_folder_users/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: "updated", text: 'user rights'});
            commit('setUpdatedUserRights', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

/*        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            doc_folder_user: updatedUsers,
        };

        axios.put(`/doc_folder_users/${id}.json`, params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', { type: 'successful', action: "updated", text: 'user rights'});
                commit('setUpdatedUserRights', res.data);
            } else {
                commit('setAlertResponse', { type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });*/
    },

//GROUP Rights

    fetchFolderGroupRights({commit}, id){
        axios.get(`/doc_folders/${id}/doc_folder_groups.json`).then(res => {
            commit('setGroupRightsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchFolderNewGroupsRightsScheme({commit}, id){
        axios.get(`/doc_folders/${id}/doc_folder_groups/new.json`).then(res => {
            commit('setGroupsRightsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    postNewGroupsRights({commit}, doc_folder_group) {
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            doc_folder_group,
        };

        axios.post(`/doc_folder_groups.json`, params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', { type: 'successful', action: "created", text: 'new group rights'});
                commit('setNewGroupRights', res.data);
            } else {
                commit('setAlertResponse', { type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });
    },

    fetchEditGroupsRights({commit}, id){
        axios.get(`/doc_folder_groups/${id}/edit.json`).then(res => {
            commit('setGroupsRightsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })

    },

    async updateGroupsRights({commit}, {updatedGroup, id}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_folder_group: updatedGroup,
            };

            const res = await  axios.put(`/doc_folder_groups/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: "updated", text: 'group rights'});
            commit('setUpdatedGroupRights', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

 /*       const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            doc_folder_group: updatedGroup,
        };

        axios.put(`/doc_folder_groups/${id}.json`, params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', { type: 'successful', action: "updated", text: 'group rights'});
                commit('setUpdatedGroupRights', res.data);
            } else {
                commit('setAlertResponse', { type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });*/
    },

    deleteGroupsRights({commit}, id) {
        axios.delete(`/doc_folder_groups/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', { type: 'successful', action: "deleted", text: 'group rights'});
                commit('setDeletedGroupRights', res.data);
            } else {
                commit('setAlertResponse', { type: 'unsuccessful' })
            }
        }).catch((error) => {
            commit('setError', error);
        });
    },

/*       Documents              */

//List Folders and Documents
    fetchProjectDocuments({commit}, id){
        axios.get(`/projects/${id}/doc_folders.json`).then(res => {
            commit('setDocumentsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
//Document Details
    fetchDocumentAbout({commit}, id){
        axios.get(`/documents/${id}.json`).then(res => {
            if(res.data.error) throw res.data.message;
            else commit('setDocumentAbout', res.data);
        }).catch(error => {
            commit('setError', error);
        })
    },

//Upload Documents

    async uploadDocuments({commit, dispatch}, {documents, labDocs= false}){
        try {
            const res = await axios.post(`/documents.json`, documents, {headers: {'Content-Type': 'multipart/form-data'}});

            if(res.data.error) return res.data;
            
            commit('setAlertResponse', {type: 'successful', action: 'uploaded', text: 'new document'});

            if(!labDocs) commit('setUploadedDocuments', res.data);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    async uploadPostImage({commit, dispatch}, image){

        try {
            const res = await axios.post(`/documents/image_base64_encoder.json`, image, { headers: {'Content-Type': 'application/json'}});
            if(res.data.error) throw res.data.message;
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    fetchSingleUploadDocScheme({commit}, id){
        axios.get(`/projects/${id}/documents/new.json`).then(res => {
            commit('setNewDocumentScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async singleUploadDocument({commit, dispatch}, file){
        try {
            const res = await axios.post(`/documents.json`, file, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'uploaded', text: 'new document'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },


//Edit Document

    fetchEditSingleDocScheme({commit}, {url, id}){
        axios.get(`${url}/documents/${id}/edit.json`).then(res => {
            commit('setSingleUploadDocScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateDocument({commit, dispatch},{id, updatedDoc} ) {
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                document: updatedDoc,
            };

            const res = await  axios.put(`/documents/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: 'updated', text: 'document'});
            dispatch('fetchDocumentAbout', id)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

    },

    async archiveDocument({commit}, { id, archived, keyDoc = false}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                archive: archived,
            };
            const text = archived ? 'archived' : 'unarchived'

            const res = await axios.post(`/documents/${id}/archive.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: text, text: 'document'});
            if(!keyDoc) commit('setArchiveDocument', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

    },

    async lockDocument({commit}, {id, lock_user_id}) {
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                lock_user_id: lock_user_id,
            };

            const text = lock_user_id === 0 ? 'unlocked' : 'locked'

            const res = await axios.post(`/documents/${id}/lock.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', {type: 'successful', action: text, text: 'document'});
            commit('setLockedDocument', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'lock', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteDocument({commit}, doc) {

        try {
            const res = await  axios.delete(`/documents/${doc.id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: doc.id,
                }
            });

            if(res.data.error) return commit('setAlertResponse', { type: 'error', text: res.data.message[0] })
            commit('setAlertResponse', { type: 'successful', action: "deleted", text: 'document'});
           /* commit('setDeletedDocument', doc);*/
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

    },

    async deleteKeyDocument({commit}, doc) {

        try {
            const res = await  axios.delete(`/documents/${doc.id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: doc.id,
                }
            });

            if(res.data.error) return commit('setAlertResponse', { type: 'error', text: res.data.message[0] })
            commit('setAlertResponse', { type: 'successful', action: "deleted", text: 'document'});
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }

    },



//Document Multiple Actions

    async downloadMultipleSelection({commit}, ids) {
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                document: {
                    id: ids
                },
            };

            const res = await axios.post('/documents/zip_selection.json', params, {

                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-Token': state.token,
                },

            });

            if(res.data.error) throw res.data.message;
            commit('setIsDownloading', true);
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    deleteMultipleSelection({commit}, idsArray){
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            document: idsArray,
        };

        let number = idsArray.length

        axios.post(`/documents/delete_selection.json`, params , {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action:  `deleted (${number})`, text: 'document(s)'});
                commit('setMultipleDelete', res.data)
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });
    },

    archiveMultipleSelection({commit}, idsArray){
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            document: idsArray,
        };
        let number = idsArray.length

        axios.post(`/documents/archive_selection.json`, params , {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: `archived (${number})`, text: 'document(s)'});
                commit('setMultipleArchive', res.data)
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });
    },

   async tagMultipleSelection({commit}, tagsProperties){
       try {

           const params = {
               charset: 'UTF8',
               authenticity_token: state.token,
               document: tagsProperties.document,
               doc_tag: tagsProperties.doc_tag,
           };

           const res = await axios.post(`/doc_tags/tag_selection.json`, params , { headers: {'Content-Type': 'application/json'}})
           if(res.data.error) throw res.data.message
           commit('setAlertResponse', {type: 'successful', action: 'tagged', text: 'documents'});
           commit('setDocFolderTags', res.data)
           return Promise.resolve();

       } catch(error) {
           commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
           return Promise.reject(error)
       }

    },

    untagMultipleSelection({commit}, idsArray){
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            document: idsArray,
        };

        axios.post(`/doc_tags/untag_selection.json`, params , { headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: 'untagged', text: 'documents'});
                commit('setDocFolderTags', res.data)
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch(error => {
            commit('setError', error.toString());
        });

    },


//Document Tags
    fetchDocumentTags({commit}, id){
        axios.get(`/documents/${id}/doc_tags.json`).then(res => {
            commit('setDocumentTags', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocumentTagsScheme({commit}, id){
        axios.get(`/documents/${id}/doc_tags/new.json`).then(res => {
            commit('setDocumentTagsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createDocumentTags({commit}, tags){

        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_tag: tags
            };

            const res = await axios.post(`/doc_tags.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "added", text: 'tag(s) to the document'});
            commit('setNewDocumentTags', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
     },


    async deleteDocumentTag({commit}, id){
        try {

            const res = await axios.delete(`/doc_tags/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "deleted", text: 'tag from the document'});
            commit('setDeletedDocumentTag', res.data)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },


//Document Comments

    fetchDocumentComments({commit}, id){
        axios.get(`/documents/${id}/doc_comments.json`).then(res => {
            commit('setDocumentComments', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewCommentScheme({commit}, id){
        axios.get(`/documents/${id}/doc_comments/new.json`).then(res => {
            commit('setNewCommentScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createDocumentComment({commit}, comment){
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_comment: comment,
            };

            const res = await axios.post(`/doc_comments.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "created", text: 'a new document comment'});
            commit('setNewComment', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    fetchEditDocumentCommentScheme({commit}, id){
        axios.get(`/doc_comments/${id}/edit.json`).then(res => {
            commit('setEditCommentScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateDocumentComment({commit}, {id, comment}){
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_comment: comment,
                id: id,
            };

            const res = await axios.put(`/doc_comments/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "updated", text: 'document comment'});
            commit('setUpdatedDocumentComment', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    async deleteDocumentComment({commit}, id){
        try {

            const res = await axios.delete(`/doc_comments/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "deleted", text: 'document comment'});
            commit('setDeleteComment', res.data)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },


//Doc -> Share

    fetchDocumentExternalShares({commit}, id){
        axios.get(`/documents/${id}/doc_share_externals.json`).then(res => {
            commit('setDocumentExternalShares', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocumentExternalSharesScheme({commit}, id){
        axios.get(`/documents/${id}/doc_share_externals/new.json`).then(res => {
            commit('setDocumentExternalSharesScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async sherDocument({commit}, {shareProps, message}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                doc_share_external: shareProps,
                doc_share_message: message,
            };

            const res = await axios.post(`/doc_share_externals.json`, params , { headers: {'Content-Type': 'application/json'}})
            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "shared", text: 'the document'});
            commit('setNewDocumentExternalShare', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error, timeout: 5000});
            return Promise.reject(error)
        }
    },

    deleteDocumentExternalShares({commit}, id){
        axios.delete(`/doc_share_externals/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: "deleted", text: 'document share record'});
                commit('setDeletedDocumentExternalShare', res.data)
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });
    },

//Document Download History

    fetchDocumentHistory({commit}, id){
        axios.get(`/documents/${id}/history.json`).then(res => {
            commit('setDocumentHistory', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Upload NEW VERSION

    fetchNewVersionScheme({commit}, id){
        axios.get(`/documents/${id}/doc_versions/new.json`).then(res => {
            commit('setNewVersionScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async uploadNewDocVersion({commit}, data) {
        try {
            const res = await axios.post(`/doc_versions.json`, data, {headers: {'Content-Type': 'multipart/form-data'}})

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', { type: 'successful', action: "uploaded", text: 'a new document version'});
            commit('setNewVersions', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error});
            return Promise.reject(error);
        }
    },

// Document Versions
    fetchDocumentVersions({commit}, id){
        axios.get(`/documents/${id}/doc_versions.json`).then(res => {
            commit('setDocumentVersions', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Fetch Version Edit.json
    fetchEditVersionScheme({commit}, id){
        axios.get(`/doc_versions/${id}/edit.json`).then(res => {
            commit('setEditVersionScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Update Version
    updateVersion({commit}, {id, updatedVersion}){
        const params = {
            charset: 'UTF8',
            authenticity_token: state.token,
            ...updatedVersion,
            id: id,
        };
        axios.put(`/doc_versions/${id}.json`, params, {headers: {'Content-Type': 'application/json'}}).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', {type: 'successful', action: "updated", text: 'document version'});
                commit('setUpdatedVersion', res.data);
            } else {
                commit('setAlertResponse', {type: 'unsuccessful'})
            }
        }).catch((error) => {
            commit('setError', error);
        });
    },

//Delete Version
   async deleteDocumentVersion({commit}, id){

        try {
            const res = await axios.delete(`/doc_versions/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message
            commit('setAlertResponse', {type: 'successful', action: "deleted", text: 'document version'});
            commit('setDeletedVersion', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', text: error});
            return Promise.reject(error);
        }

    },


// Article Documents

    fetchNewArticleDocumentsScheme({commit}, id){
        axios.get(`/topic_articles/${id}/documents/new.json`).then(res => {
            commit('setNewArticleDocumentsScheme', res.data);
            commit('setNewDocumentScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

//Lab Documents

    fetchLabDocuments({commit}, id){
        axios.get(`/labs/${id}/lab_docs.json`).then(res => {
            commit('setDocumentsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewLabDocumentsScheme({commit}, id){
        axios.get(`/labs/${id}/documents/new.json`).then(res => {
            commit('setNewLabDocumentsScheme', res.data);
            commit('setNewDocumentScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

 //Topic (MaCo) Documents

    fetchTopicDocuments({commit}, id) {
        axios.get(`/doc_folders/${id}.json`).then(res => {
            commit('setDocumentsList', [{...res.data}]);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchArchivedTopicDocuments({commit}, id) {
        axios.get(`/doc_folders/${id}/show_archived_documents.json`).then(res => {
            commit('setDocumentsList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchNewTopicDocumentsScheme({commit}, id){
        axios.get(`/topics/${id}/documents/new.json`).then(res => {
            commit('setNewTopicDocumentsScheme', res.data);
            commit('setNewDocumentScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
}
