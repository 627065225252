import axios from 'axios';

/* * * STATE * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,

//Batches
    batches: null,

//Batch
    batch: null,
    newBatch: null,
    batchLabelScheme: null,

//Batch Fields
    batchFields: null,
    newBatchFieldScheme: null,
    editBatchFieldScheme: null,

    uploadedResponse: null,
    newBatchResponse: false,
    batchStaffScheme: null,

//Batch Staff
    usersBatchDropdown: null,
    rolesBatchDropdown: null,
    batchStaffParams: null,
}

/* * * GETTERS * * */
const getters = {

//Batches
    batches: state => state.batches,

//Batch
    batch: state => state.batch,
    batchLabelScheme: state =>state.batchLabelScheme,

//Batch Fields
    batchFields: state => state.batchFields,
    newBatchFieldScheme: state => state.newBatchFieldScheme,
    editBatchFieldScheme: state => state.editBatchFieldScheme,

    uploadedResponse: state => state.uploadedResponse,
    newBatchResponse: state => state.newBatchResponse,
    batchStaffScheme: state => state.batchStaffScheme,
//Batch Staff
    usersBatchDropdown: state => state.usersBatchDropdown,
    rolesBatchDropdown: state => state.rolesBatchDropdown,
    batchStaffParams: state => state.batchStaffParams,
}

/* * * MUTATIONS * * */
const mutations = {
//Batches
    setBatches: (state, data) => state.batches = data,
    setArchivedBatch: (state, data) => {
        const batchesCopy = state.batches
        let batchIndex = state.batches.findIndex((batch => batch.id == data.id));
        batchesCopy[batchIndex].batch_archive = data.batch_archive
        state.batches = null
        state.batches = batchesCopy
    },

//Batch
    setBatch: (state, batch) => state.batch = batch,
    setBatchLabelScheme: (state, data) => state.batchLabelScheme = data,
    setNewCreatedBatch(state, newBatch){
        state.batches.push(newBatch)
    },
    setUpdateBatchLabel: (state, updated) => {
        const batchesCopy = state.batches
        let batchIndex = batchesCopy.findIndex((batch => batch.id ==  updated.id));
        batchesCopy[batchIndex].batch_label = updated.batch_label
        state.batches = null
        state.batches = [...batchesCopy]
    },
    setDeleteBatch(state, id){
        const batchesCopy = state.batches.filter((batch => batch.id !== id));
        state.batches = null
        state.batches = batchesCopy
    },

//Batch Fields
    setBatchFields: (state, batchFields) => {
        state.batchFields = batchFields
    },
    setNewBatchFieldsScheme: (state, data) => state.newBatchFieldScheme = data,
    setEditBatchFieldScheme: (state, data) => state.editBatchFieldScheme = data,

/*    setUpdateDataField(state, { data: fields, id: id}){
          const batchesCopy = state.batches
          let batchIndex = batchesCopy.findIndex((batch => batch.id == id));
          batchesCopy[batchIndex].batch_fields = [...fields]
          state.batches = null
          state.batches = [...batchesCopy]
    },*/

    setUploadedResponse: (state, uploadedResponse) => state.uploadedResponse = uploadedResponse,
    setNewBatchResponse:  (state, data) => state.newBatchResponse = data,

//Batch Staff
    setBatchStaffScheme: (state, data) => state.batchStaffScheme = data,
    setUpdatedBatchStaff: (state, data) => {
        let batchesCopy = state.batches
        let batchIndex = batchesCopy.findIndex((batch => batch.id === data.id));
        batchesCopy[batchIndex] = data
        state.batches = null
        state.batches = batchesCopy
    },

    setBatchUsersDropdown: (state, usersBatchDropdown) => state.usersBatchDropdown = usersBatchDropdown,
    setBatchRolesDropdown: (state, rolesBatchDropdown) => state.rolesBatchDropdown = rolesBatchDropdown,
    setBatchStaffParams: (state, params) => {
        let staff = {
            ...state.batchStaffParams,
            ...params,
        }
        state.batchStaffParams = staff
    },
}

/* * * ACTIONS * * */
const actions = {

    //Batches
    fetchBatches({commit}, id) {
            axios.get(`/projects/${id}/planning.json`).then(res => {
                commit('setBatches', res.data);
            }).catch(error => {
                commit('setError', error.toString());
            })
        },

    async archiveBatch({commit}, {id, archived}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch: {
                    batch_archive: archived,
                }
            };
            let text = archived ? 'archived' : 'unarchived'

            const res = await axios.post(`/batches/${id}/archive.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: text, text: 'participation'});
            commit('setArchivedBatch', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'uploaded', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteBatch({commit}, {id, hideAlert = false}) {
        try {
            const res = await  axios.delete(`/batches/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            if(!hideAlert) commit('setAlertResponse', { type: 'successful', action: 'deleted', text: 'the batch'});
            commit('setDeleteBatch', id);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'uploaded', text: error, timeout: 6000});
            return Promise.reject(error)
        }

    },

    // Upload Batch(es)
    async uploadImportBatch({commit}, data) {
        try {
            const res = await  axios.post(`/batches_imports.json`, data, {headers: {'Content-Type': 'multipart/form-data'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'uploaded', text: 'planning data'});
            commit('setUploadedResponse', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'uploaded', text: error, timeout: 10000});
            return Promise.reject(error)
        }
    },

//Manually Create Batch
    fetchNewBatchLabelScheme({commit}, id) {
        axios.get(`/projects/${id}/batches/new.json`).then(res => {
            commit('setBatchLabelScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createNewBatch({commit},  batch){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch: batch,
            };

            const res = await axios.post(`/batches.json`, params , {headers: {'Content-Type': 'application/json'}})


            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'created', text: 'a new batch', pageType: 'newBatchLAbel',});
            commit('setBatch', res.data);
            commit('setNewCreatedBatch', res.data);
            commit('setNewBatchResponse', true)
            return Promise.resolve(res.data);

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'Create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    resetNewBatchResponse({commit}){
        commit('setNewBatchResponse', false);
    },

    fetchEditBatchLabelScheme({commit}, id) {
        axios.get(`/batches/${id}/edit.json`).then(res => {
            commit('setBatchLabelScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateBatchLabel({commit, dispatch},{ id, batch, isPopUp = false }){
        try {

            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch: batch,
            };

            const res = await axios.put(`/batches/${id}.json`, params)

            if(res.data.error) {
                if(isPopUp) dispatch('fetchBatches', batch.project_id)
                throw res.data.message
            }
            commit('setAlertResponse', { type: 'successful', action: 'updated', text: 'the batch label', pageType: 'updatedBatchLabel'});
            commit('setUpdateBatchLabel', res.data);
            dispatch('fetchBatch', id)
            dispatch('fetchBatches', batch.project_id)
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    fetchBatch({commit}, id){
        axios.get(`batches/${id}.json`).then(res => {
            commit('setBatch', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchBatchFields({commit}, id){
        axios.get(`batches/${id}.json`).then(res => {
            commit('setBatchFields', res.data.batch_fields);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    //Field Actions
    fetchNewBatchFieldsScheme({commit}, id){
        axios.get(`/batches/${id}/batch_fields/new.json`).then(res => {
            commit('setNewBatchFieldsScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchEditBatchFieldScheme({commit}, id){
        axios.get(`/batch_fields/${id}/edit.json`).then(res => {
            commit('setEditBatchFieldScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createNewBatchField({commit, dispatch},  { batch_fields , batch_id, project_id}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_field: batch_fields,
            };

            const res = await axios.post(`/batch_fields.json`, params , {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            if(Array.isArray(res.data)) {
                commit('setAlertResponse', { type: 'successful', action: 'created', text: 'a new batch field', pageType: 'newFiled'});
                dispatch('fetchBatches', project_id);
                dispatch('fetchBatchFields', batch_id);
                return Promise.resolve();
            }
            return Promise.resolve();
        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'Create', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async updateBatchField({commit, dispatch}, {id, batch_field, batch_id, project_id, isPopUp = false}){

        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_field: batch_field,
                id: id
            };

            const res = await axios.put(`/batch_fields/${id}.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) {
                if(isPopUp) dispatch('fetchBatches', project_id)
                throw res.data.message
            } else {
                commit('setAlertResponse', { type: 'successful', action: 'updated', text: 'batch field', pageType: 'updatedFiled'});
                dispatch('fetchBatches', project_id);
                dispatch('fetchBatchFields', batch_id);
                return Promise.resolve()
            }


        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    async deleteBatchField({commit, dispatch}, {id, batch_id, project_id}){
        try {

            const res = await  axios.delete(`/batch_fields/${id}.json`, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': state.token,
                    id: id,
                }
            })

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'deleted', text: 'batch field'});
            dispatch('fetchBatches', project_id);
            dispatch('fetchBatchFields', batch_id);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'delete', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

//Batch Staff
    fetchBatchNewStaffScheme({commit}, id) {
        axios.get(`/batches/${id}/batch_staffs/new.json`).then(res => {
            commit('setBatchUsersDropdown', res.data[0]);
            commit('setBatchRolesDropdown', res.data.slice(-1).pop());
            commit('setBatchStaffScheme', res.data);

        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async createBatchStaff({commit},{batchStaff, id}) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_staff: batchStaff,
                batch_id: id,
            };

            const res = await axios.post(`/batch_staffs.json`, params, {headers: {'Content-Type': 'application/json'}})

            if(res.data.error) throw res.data.message;
            commit('setAlertResponse', { type: 'successful', action: 'added', text: 'batch staff', pageType: 'newBatchStaff'});
            commit('setUpdatedBatchStaff', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'uploaded', text: error, timeout: 8000});
            return Promise.reject(error)
        }
    },

    setBatchStaffParams({commit}, params){
        commit('setBatchStaffParams', params);
    },

    fetchBatchEditStaffScheme({commit}, id){
        axios.get(`/batch_staffs/${id}/edit.json`).then(res => {
            commit('setBatchStaffScheme', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    async updateBatchStaff({commit}, {id, updatedBatchStaff}){
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                batch_staff: updatedBatchStaff,
            };

            const res = await axios.put(`/batch_staffs/${id}.json`, params)

            if(res.data.error) throw res.data.message;
            console.log('updated')
            commit('setAlertResponse', { type: 'successful', action: 'updated', text: 'batch staff'});
            commit('setUpdatedBatchStaff', res.data);
            return Promise.resolve();

        } catch(error) {
            commit('setAlertResponse', { type: 'error', action: 'uploaded', text: error, timeout: 8000});
            return Promise.reject(error)
        }

    },

    deleteBatchStaff({commit}, id){
        axios.delete(`/batch_staffs/${id}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': state.token,
                id: id,
            }
        }).then(res => {
            if(res.status === 201 || res.status === 200){
                commit('setAlertResponse', { type: 'successful', action: 'deleted', text: 'batch staff'});
                commit('setUpdatedBatchStaff', res.data);
            } else {
                commit('setAlertResponse', { type: 'unsuccessful'});
            }
        }).catch((error) => {
            commit('setError', error);
        });
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
}
