import axios from 'axios';

const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    keyDocuments: null,
    docsPublicFolders: null,
    docsPublicFoldersList: null,
    docsSharedFolders: null,
}
const getters = {
    keyDocuments: state => state.keyDocuments,
    docsPublicFolders: state => state.docsPublicFolders,
    docsPublicFoldersList: state => state.docsPublicFoldersList,
    docsSharedFolders: state => state.docsSharedFolders
}

const mutations = {
    setKeyDocuments: (state, data ) => state.keyDocuments = data,
    setDocsPublicFolders: (state, data) => state.docsPublicFolders = data,
    setDocsPublicFoldersList: (state, data) => state.docsPublicFoldersList = data,
    setDocsSharedFolders: (state, data) => state.docsSharedFolders = data
}

const actions = {
    fetchKeyDocuments({commit}) {
        axios.get('/documents.json').then(res => {
            commit('setKeyDocuments', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocsPublicFolders({commit}) {
        axios.get('/pub_folders.json').then(res => {
            commit('setDocsPublicFolders', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocsPublicFolderListDocuments({commit}, id) {
        axios.get(`/doc_folders/${id}.json`).then(res => {
            commit('setDocsPublicFoldersList', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    fetchDocsSharedFolders({commit}) {
        axios.get('/shared_folders.json').then(res => {
            commit('setDocsSharedFolders', res.data);
        }).catch(error => {
            commit('setError', error.toString());
        })
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
}
