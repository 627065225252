import axios from 'axios';

const state = {
    memberships:[
        {
            title:"A",
            text:"A Members",
            type:true
        },
        {
            title:"B",
            text:"B Members",
            type:true
        },
        {
            title:"C",
            text:"C Members",
            type:true
        },
        {
            title:"BAF",
            text:"B Members Affiliate",
            type:true
        },
        {
            title:"CAF",
            text:"C Members Affiliate",
            type:true
        },
        {
            title:"CM",
            text:"Candidate Member",
            type:true
        },
    ],
    regions:[
        {
            title:"Nucleus",
            type:true
        },
        {
            title:"Euroconsumers",
            type:true
        },
        {
            title:"Asia Pacific",
            type:true
        },
        {
            title:"Central and Eastern European",
            type:true
        },
        {
            title:"Latin America",
            type:true
        },
        {
            title:"North America",
            type:true
        },
        {
            title:"Nordic",
            type:true
        },
        {
            title:"Associate",
            type:true
        },
        {
            title:"ICRT",
            type:true
        },
        {
            title:"None",
            type:true
        },
    ],
}
const getters = {
    memberships: state => state.memberships,
    regions: state => state.regions,
}

const mutations = {

}

const actions = {

}
export default {
    state,
    getters,
    mutations,
    actions,
}
