import axios from 'axios';

/* STATE * * * * * * * * * * * * * * * * * * */
const state = {
    token: document.getElementsByTagName('meta')['csrf-token'].content,
    searchingWords: null,
    searchResults: null,
    constantResults: null,
    searchFilterScheme: null,
    peopleResults: null,
}

/* GETTERS * * * * * * * * * * * * * * * * * * */
const getters = {
    searchingWords: state => state.searchingWords,
    searchResults: state => state.searchResults,
    constantResults: state => state.constantResults,
    searchFilterScheme: state => state.searchFilterScheme,
    peopleResults: state => state.peopleResults,
}

/* MUTATIONS * * * * * * * * * * * * * * * * * * */
const mutations = {
    setSearchingWords: (state, data) =>   state.searchingWords = data,
    setSearchResults: (state, data) =>  state.searchResults = data,
    setConstantResults: (state, data) =>  state.constantResults = data,
    setSearchFilterScheme: (state, data) =>   state.searchFilterScheme = data,
    setPeopleResults: (state, data) =>   state.peopleResults = data,
    resetPeopleResults: state => state.peopleResults = null,
    updateSearchResults: (state, data) => {
        let copySearchResults = state.searchResults
        let projectIndex = state.searchResults.project.findIndex((project => project.id ===  data.id));
        copySearchResults.project[projectIndex].name = `${data.prefix}${data.icrtcode} ${data.name}`
        state.searchResults = null
        state.searchResults = copySearchResults
    },
}



/* ACTIONS * * * * * * * * * * * * * * * * * * */
const actions = {
    async fetchSearchResults({commit}, searchingText) {
        try {
            const res = await axios.get(`/search.json?q=${encodeURIComponent(searchingText)}`)
            if(res.data.error) throw res.data.message
            commit('setSearchingWords', searchingText);
            commit('setSearchResults', res.data);
            commit('setConstantResults', res.data);
            commit('setSearchFilterScheme', res.data.filters);
            return Promise.resolve();

        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    async postSearchFilters({commit}, search) {
        try {
            const params = {
                charset: 'UTF8',
                authenticity_token: state.token,
                search
            };

            const res =  await axios.post(`/search.json?`, params )
            if(res.data.error) throw res.data.message
            commit('setSearchResults', res.data.results);
            commit('setSearchingWords', search.q);
            return Promise.resolve();

        } catch (error) {
            commit('setAlertResponse', {type: 'error', action: '', text: error, timeout: 5000});
            return Promise.reject();
        }
    },

    fetchPeopleResults({commit}, search) {

        axios.get(`/addressbook/people_finder.json`, { params: { search: search} } ).then(res => {
            if(res.status === 201 || res.status === 200) return commit('setPeopleResults', res.data);
            else return commit('setAlertResponse', {type: 'unsuccessful'})

        }).catch(error => {
            commit('setError', error.toString());
        })
    },

    clearPeopleResults({commit}) {
        commit('resetPeopleResults');
    },

}


export default {
    state,
    getters,
    mutations,
    actions,
}